// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, THEMES, TITLES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
const AcEditHammerTypeModal = loadable(() =>
  import(
    '@molecules/ac-edit-hydrohammer-type-modal/ac-edit-hydrohammer-type-modal.web'
  )
);

// Imports => Components
const AcDetailsCard = loadable(() =>
  import('@components/ac-details-card/ac-details-card.web')
);
const AcHeroVisual = loadable(() =>
  import('@components/ac-hero-visual/ac-hero-visual.web')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcDropzone = loadable(() => import('@atoms/ac-dropzone/ac-dropzone.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-hydrohammer-type-detail-overview-tab',
};

const AcHydrohammerTypeDetailOverviewTab = ({
  data,
  store: { hydrohammer_types, ui },
}) => {
  const { can } = usePermissions();
  const { is_loading, is_busy } = hydrohammer_types;

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_HYDROHAMMER_TYPE}: ${data.name}`,
      body: (
        <AcEditHammerTypeModal data={data} submit={hydrohammer_types.update} />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleRemoveMedia = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    hydrohammer_types.delete_media(data.id);
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;
    const {
      name,
      type,
      series,
      description,
      jean_lutz_hammer_type,
      article_number,
    } = data;

    const items = [
      [
        {
          label: 'Name',
          value: name || '-',
        },
        {
          label: 'Type',
          value: type || '-',
        },
        {
          label: 'Series',
          value: series || '-',
        },
      ],
      [
        {
          label: 'Article number',
          value: article_number || '-',
        },
        {
          label: 'Description',
          value: description || '-',
        },
        {
          label: 'Sensor configuration',
          value: jean_lutz_hammer_type || '-',
        },
      ],
    ];

    const edit = can(PERMISSIONS.EQUIPMENT_TYPE.UPDATE) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={items} edit={edit} />;
  }, [data]);

  const renderDropzone = useMemo(() => {
    if (!AcIsSet(data) || !AcIsSet(data.images)) return null;

    return (
      <AcDropzone
        id={data.id}
        value={data.images.header}
        callback={(id, output, options) => {
          const payload = output;
          if (data?.article_number)
            payload.append('article_number', data.article_number);
          if (data?.type) payload.append('name', data.type);
          if (data?.series) payload.append('series', data.series);
          hydrohammer_types.update(id, payload, options);
        }}
        loading={is_busy}
      />
    );
  }, [data, is_busy]);

  const renderHeroVisual = useMemo(() => {
    if (!data?.images?.header) return null;

    return (
      <AcHeroVisual image={data.images.header} className={'h-margin-x-auto'} />
    );
  }, [data]);

  const getRemoveMediaButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      disabled: is_loading || is_busy,
      title: 'Remove',
      callback: handleRemoveMedia,
    };
  }, [is_loading, is_busy]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            {renderDetails}
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcCard flat>
              <AcHeading tag={'h2'} rank={6}>
                Equipment image
              </AcHeading>

              {!AcIsSet(data?.images?.header) && renderDropzone}

              {AcIsSet(data?.images?.header) && (
                <>
                  {renderHeroVisual}

                  <div className={'h-margin-top-15 h-flex-h-align-end'}>
                    <AcButton {...getRemoveMediaButtonOptions}>
                      <span>Remove</span>
                    </AcButton>
                  </div>
                </>
              )}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcHydrohammerTypeDetailOverviewTab));
